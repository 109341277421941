import { useLocation } from '@reach/router'
import React, { useEffect } from 'react'
import queryString from 'query-string'
import useLocalStorage from '~hooks/useLocalStorage'
import { TAuthUser } from '~contexts/authContext/authContext.types'
import useWebsite from '~hooks/useWebsite'

const session: React.FC = () => {
	const location = useLocation()
	const { token } = queryString.parse(location.search)
	const website = useWebsite()
	const [user, setUser] = useLocalStorage<TAuthUser>('user')
	const [, setToken] = useLocalStorage<string>('token')

	const checkToken = async () => {
		try {
			if (!user?.email) {
				const headers = new Headers()
				headers.append('x-transfer-token', token as string)
				const result = await fetch(
					`${process.env.GATSBY_ADMIN_URI}/auth/login`,
					{
						method: 'POST',
						headers,
						redirect: 'follow'
					}
				)

				if (result.status === 200) {
					const data = await result.json()

					if (data.token) {
						const activities = website.activitiesId || []

						activities.forEach((id) => {
							if (!data.user.activities.includes(id)) throw 'error'
						})

						setUser(data.user)
						setToken(data.token)
					}
				}
			}

			window.location.href = '/admin'
		} catch (err) {
			console.error(err)
		}
	}
	useEffect(() => {
		checkToken()
	}, [token])
	return (
		<div style={{ textAlign: 'center', paddingTop: '60px' }}>
			<div style={{ width: '700px', maxWidth: '90%', margin: 'auto' }}>
				<h1>
					<i className="fas fa-spin fa-spinner" />
				</h1>
				<br />
				<p>Connexion en cours</p>
			</div>
		</div>
	)
}

export default session
